
<template>
  <div class="page">
    <div style="display: flex; justify-content: flex-end">
      <a-icon type="close" @click="closeDetail" />
    </div>
    <a-descriptions title="楼栋信息" layout="vertical">
      <a-descriptions-item label="楼栋名称">
        {{ placeDetail.blockCode || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="所属社区">{{
        placeDetail.gridPname || "--"
      }}</a-descriptions-item>
      <a-descriptions-item label="所属网格">
        {{ placeDetail.gridName || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="房屋类型">
        {{
          placeDetail.houseType === "0"
            ? "单元楼"
            : placeDetail.houseType === "1"
            ? "筒子楼"
            : placeDetail.houseType === "2"
            ? "别墅"
            : placeDetail.houseType === "3"
            ? "自建楼"
            : placeDetail.houseType === "4"
            ? "平房"
            : placeDetail.houseType === "5"
            ? "其他"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="楼长姓名"
        >{{ placeDetail.managerName || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="楼长联系方式"
        >{{ placeDetail.managerPhone || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="单元数">
        {{ placeDetail.unitNumber || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="最高层数">
        {{ placeDetail.topFloor || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="每层户数">
        {{ placeDetail.perFloor || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="常住人口">
        {{ placeDetail.actualPopulation || "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="楼栋可视化">
        <div style="color: #e6a23cff; display: flex; align-items: center">
          <i class="iconfont icon-loudongkeshihua build"></i>
          <span
            @click="
              $router.push(
                `/communityPages/gridPlanning/buildingList/visual?id=${placeDetail.id}`
              )
            "
            >前往查看</span
          >
        </div>
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
 
 <script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["placeDetail"],
  methods: {
    closeDetail() {
      this.$emit("closeDetail");
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
.page {
  background-color: #fff;
  border-radius: 10px;
  padding: 2%;
  .build {
    margin-right: 8px;
    font-size: 0.1rem;
  }
}
</style>